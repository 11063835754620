import SvgIconWrapper from "./SvgIconWrapper";

export default function TruckAndTrainIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_391_27533)">
          <path
            d="M3.41422 24L23 24C23.5523 24 24 23.5523 24 23L24 3.41422C24 2.52331 22.9229 2.07714 22.2929 2.70711L2.70711 22.2929C2.07714 22.9229 2.52331 24 3.41422 24Z"
            fill="currentColor"
          />
          <mask
            id="mask0_391_27533"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="23"
            height="23"
          >
            <path d="M1 24L24 24L24 1L1 24Z" fill="currentColor" />
          </mask>
          <g mask="url(#mask0_391_27533)">
            <path
              d="M9.16372 15.502L9.01675 16.9343C8.92387 17.8397 9.22034 18.7465 9.83019 19.422C10.1514 19.7779 10.5453 20.0537 10.9792 20.2352L9.77411 22.7537C9.65926 22.9937 9.76072 23.2814 10.0007 23.3962C10.0678 23.4283 10.1386 23.4435 10.2083 23.4435C10.388 23.4435 10.5605 23.3425 10.6433 23.1696L11.0588 22.3011H20.9412L21.3567 23.1696C21.4395 23.3425 21.6119 23.4435 21.7917 23.4435C21.8614 23.4435 21.9322 23.4283 21.9993 23.3962C22.2393 23.2814 22.3407 22.9937 22.2259 22.7537L21.0208 20.2352C21.4547 20.0537 21.8486 19.7779 22.1698 19.422C22.7797 18.7465 23.0761 17.8397 22.9833 16.9343L22.8371 15.5091C22.8371 15.5091 9.18986 15.5063 9.16372 15.502ZM11.5199 21.3376L11.9295 20.4814C11.9311 20.4783 11.9321 20.4751 11.9335 20.4719C12.029 20.4805 19.971 20.4805 20.0665 20.4719C20.0679 20.4751 20.0689 20.4783 20.0705 20.4814L20.4801 21.3376H11.5199ZM19.3563 16.5068C20.1342 16.5068 20.7671 17.1397 20.7671 17.9176C20.7671 18.6956 20.1342 19.3285 19.3563 19.3285C18.5784 19.3285 17.9455 18.6956 17.9455 17.9176C17.9455 17.1397 18.5784 16.5068 19.3563 16.5068ZM12.6437 16.5068C13.4217 16.5068 14.0546 17.1397 14.0546 17.9176C14.0546 18.6956 13.4217 19.3285 12.6437 19.3285C11.8658 19.3285 11.2329 18.6956 11.2329 17.9176C11.2329 17.1397 11.8658 16.5068 12.6437 16.5068Z"
              fill="white"
            />
            <path
              d="M19.3562 18.3653C19.6029 18.3653 19.8035 18.1647 19.8035 17.918C19.8035 17.6713 19.6029 17.4707 19.3562 17.4707C19.1096 17.4707 18.9089 17.6713 18.9089 17.918C18.9089 18.1647 19.1096 18.3653 19.3562 18.3653Z"
              fill="white"
            />
            <path
              d="M12.6436 18.3653C12.8902 18.3653 13.0909 18.1647 13.0909 17.918C13.0909 17.6713 12.8902 17.4707 12.6436 17.4707C12.397 17.4707 12.1963 17.6713 12.1963 17.918C12.1963 18.1647 12.397 18.3653 12.6436 18.3653Z"
              fill="white"
            />
            <path
              d="M22.1609 8.9196C22.0486 7.82523 21.1344 7 20.0343 7H11.9657C10.8656 7 9.9514 7.82523 9.83912 8.9196L9.26184 14.5464H22.7382L22.1609 8.9196ZM20.704 13.1027C20.6126 13.2039 20.4827 13.2617 20.3463 13.2617H11.6537C11.5173 13.2617 11.3874 13.2039 11.296 13.1027C11.2047 13.0016 11.1605 12.8664 11.1744 12.7308L11.5861 8.71729C11.6114 8.47153 11.8184 8.2847 12.0654 8.2847H19.9346C20.1817 8.2847 20.3887 8.47153 20.4139 8.71729L20.8256 12.7308C20.8395 12.8664 20.7954 13.0015 20.704 13.1027Z"
              fill="white"
            />
            <path
              d="M19.4999 9.24805H17.9677V12.298H19.8128L19.4999 9.24805Z"
              fill="white"
            />
            <path
              d="M15.004 9.24805H17.0039V12.298H15.004V9.24805Z"
              fill="white"
            />
            <path
              d="M12.5004 9.24805L12.1875 12.298H14.0407V9.24805H12.5004Z"
              fill="white"
            />
          </g>
          <path
            d="M20.5858 0H1C0.447715 0 0 0.447715 0 1V20.5858C0 21.4767 1.07714 21.9229 1.70711 21.2929L21.2929 1.70711C21.9229 1.07714 21.4767 0 20.5858 0Z"
            fill="currentColor"
          />
          <mask
            id="mask1_391_27533"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="23"
            height="23"
          >
            <path d="M23 0H0V23L23 0Z" fill="currentColor" />
          </mask>
          <g mask="url(#mask1_391_27533)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.21797 13.5639H2.3779C2.48862 12.3459 3.52201 11.3987 4.76454 11.3987C6.00707 11.3987 7.04046 12.3459 7.15118 13.5639H11.0387C11.1617 12.3459 12.1828 11.3987 13.4253 11.3987C14.6802 11.3987 15.7012 12.3459 15.8243 13.5639H16.0703C16.1933 12.3459 17.2144 11.3987 18.4693 11.3987C19.7118 11.3987 20.7329 12.3459 20.8559 13.5639C21.4956 13.5393 22 13.0103 22 12.3582V10.3407C22 10.2053 21.8893 10.0946 21.754 10.0946H7.61866V4.86616C7.61866 4.73084 7.50794 4.62012 7.37262 4.62012H5.93325V7.86791C5.93325 8.61835 5.33044 9.22116 4.58001 9.22116H1.00005V12.3582C1.01235 13.0226 1.55365 13.5639 2.21797 13.5639ZM5.14591 10.3284C5.6257 10.3284 6.14239 10.4268 6.14239 10.0823C6.14239 9.73786 5.6257 9.83627 5.14591 9.83627C4.82605 9.83627 4.82605 10.3284 5.14591 10.3284ZM5.44116 4.62012H3.9895C2.341 4.62012 1.00005 5.96106 1.00005 7.60957V8.72907H4.58001C5.0598 8.72907 5.44116 8.3477 5.44116 7.86791V4.62012Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4252 15.7906C14.5325 15.7906 15.4182 14.8926 15.4182 13.7977C15.4182 12.7028 14.5325 11.8047 13.4252 11.8047C12.3303 11.8047 11.4446 12.7028 11.4446 13.7977C11.4446 14.8926 12.3303 15.7906 13.4252 15.7906ZM13.4252 14.7818C14.717 14.7818 14.717 12.8135 13.4252 12.8135C12.1458 12.8135 12.1458 14.7818 13.4252 14.7818Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.4693 15.7906C19.5642 15.7906 20.45 14.8926 20.45 13.7977C20.45 12.7028 19.5642 11.8047 18.4693 11.8047C17.3621 11.8047 16.4763 12.7028 16.4763 13.7977C16.4763 14.8926 17.3621 15.7906 18.4693 15.7906ZM18.4693 14.7818C19.7487 14.7818 19.7487 12.8135 18.4693 12.8135C17.1776 12.8135 17.1776 14.7818 18.4693 14.7818Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.76448 15.7906C5.85938 15.7906 6.75745 14.8926 6.75745 13.7977C6.75745 12.7028 5.85938 11.8047 4.76448 11.8047C3.66958 11.8047 2.77151 12.7028 2.77151 13.7977C2.77151 14.8926 3.66958 15.7906 4.76448 15.7906ZM4.76448 14.7818C6.05622 14.7818 6.05622 12.8135 4.76448 12.8135C3.47274 12.8135 3.47274 14.7818 4.76448 14.7818Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.55379 9.23373H21.4343C21.5696 9.23373 21.6803 9.12301 21.6803 8.98768V2.24605C21.6803 2.11072 21.5696 2 21.4343 2H8.55379C8.41847 2 8.30774 2.11072 8.30774 2.24605V8.98768C8.30774 9.12301 8.41847 9.23373 8.55379 9.23373ZM19.7611 8.0035C19.8965 8.0035 20.0072 7.89278 20.0072 7.75746V3.47627C20.0072 3.15641 19.5151 3.15641 19.5151 3.47627V7.75746C19.5151 7.89278 19.6258 8.0035 19.7611 8.0035ZM17.8543 8.0035C17.9896 8.0035 18.1003 7.89278 18.1003 7.75746V3.47627C18.1003 3.15641 17.6083 3.15641 17.6083 3.47627V7.75746C17.6083 7.89278 17.719 8.0035 17.8543 8.0035ZM15.9474 8.0035C16.0828 8.0035 16.1935 7.89278 16.1935 7.75746V3.47627C16.1935 3.15641 15.7014 3.15641 15.7014 3.47627V7.75746C15.7014 7.89278 15.8121 8.0035 15.9474 8.0035ZM14.0406 8.0035C14.1759 8.0035 14.2866 7.89278 14.2866 7.75746V3.47627C14.2866 3.15641 13.7946 3.15641 13.7946 3.47627V7.75746C13.7946 7.89278 13.9053 8.0035 14.0406 8.0035ZM12.1337 8.0035C12.2691 8.0035 12.3798 7.89278 12.3798 7.75746V3.47627C12.3798 3.15641 11.8877 3.15641 11.8877 3.47627V7.75746C11.8877 7.89278 11.9984 8.0035 12.1337 8.0035ZM10.2269 8.0035C10.3622 8.0035 10.4729 7.89278 10.4729 7.75746V3.47627C10.4729 3.15641 9.98085 3.15641 9.98085 3.47627V7.75746C9.98085 7.89278 10.0916 8.0035 10.2269 8.0035Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.1775 10.7962C10.4235 10.7962 10.6203 10.5871 10.6203 10.3411V8.9878C10.6203 8.39729 9.72228 8.39729 9.72228 8.9878V10.3411C9.72228 10.5871 9.93142 10.7962 10.1775 10.7962ZM19.8347 10.7962C20.0931 10.7962 20.2899 10.5871 20.2899 10.3411V8.9878C20.2899 8.39729 19.3919 8.39729 19.3919 8.9878V10.3411C19.3919 10.5871 19.5887 10.7962 19.8347 10.7962Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_391_27533">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
